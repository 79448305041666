import { Meta, Title } from '@solidjs/meta';
import { Button, Container, Dialog, DialogContent, Heading, Page, Section } from '@troon/ui';
import { Match, Show, Suspense, Switch, createEffect, createMemo, createSignal, onMount } from 'solid-js';
import { createAsync, useLocation, useNavigate, useSearchParams } from '@solidjs/router';
import { WindowVirtualizer } from 'virtua/solid';
import { IconArrowUpMd } from '@troon/icons/arrow-up-md';
import { useWindowScrollPosition } from '@solid-primitives/scroll';
import { createIntersectionObserver } from '@solid-primitives/intersection-observer';
import { createStore } from 'solid-js/store';
import { IconSearchMagnifyingGlass } from '@troon/icons/search-magnifying-glass';
import { gql } from '../../../../graphql';
import { cachedQuery } from '../../../../graphql/cached-get';
import { Grid, GridFive, GridSeven } from '../../../../components/layouts/grid';
import { FacilityCard } from '../../../../components/facility-search/card';
import { Map } from '../../../../components/facility-search/map';
import { toSearchSchema, zodGetDefaults } from '../../../../modules/search-store';
import { teeTimeSearchSchema } from '../../../../components/tee-time-search';
import { getConfigValue } from '../../../../modules/config';
import { ShareButtons } from '../../../../components/share-buttons';
import { TeeTimeSearchHeader } from '../../../../components/tee-time-search/header';
import type { FacilityCardFragment } from '../../../../components/facility-search/card';
import type { FragmentType } from '../../../../graphql';
import type { MapHandler } from '../../../../components/facility-search/map';
import type { ParentProps } from 'solid-js';
import type { WindowVirtualizerHandle } from 'virtua/solid';
import type { z } from 'zod';

type Props = ParentProps<{
	location: string;
	title: string;
	image: string;
	description?: string;
	facilities: Record<string, { tel: string; url?: string }>;
}>;

const SCROLL_OFFSET = -275; // magic number
const sessionKeyPrefix = 'coop-scroll';

export function TroonGolfLandingPages(props: Props) {
	const data = createAsync(async () => getFacilities({ slugs: Object.keys(props.facilities) }), { deferStream: true });
	const [, setSearchParams] = useSearchParams();
	const scroll = useWindowScrollPosition();
	const [virtualizer, setVirtualizer] = createSignal<WindowVirtualizerHandle>();
	const [targets, setTargets] = createSignal<Array<HTMLElement>>([]);
	const [mapRef, setMapRef] = createSignal<MapHandler>();
	const [enableFocus, setEnableFocus] = createSignal(true);
	const [focused, setFocused] = createSignal<FragmentType<typeof FacilityCardFragment>>();
	const [mapExpanded, setMapExpanded] = createSignal(false);
	const [highlighted, setHighlighted] = createSignal<Array<Element>>([]);
	const [store, setStore] = createStore<z.infer<typeof teeTimeSearchSchema>>(zodGetDefaults(teeTimeSearchSchema));
	const navigate = useNavigate();
	const location = useLocation();

	const shareData = createMemo<ShareData>(() => ({
		title: props.title,
		text: props.description,
		url: `https://${getConfigValue('HOST')}/${location.pathname}`,
	}));

	onMount(() => {
		setSearchParams({ utm_campaign: 'coop-site', utm_source: props.location.toLowerCase() });
	});

	createIntersectionObserver(
		targets,
		(entries) => {
			if (window.scrollY < Math.abs(SCROLL_OFFSET)) {
				return;
			}
			setHighlighted((s) => {
				for (const el of entries) {
					const idx = s.indexOf(el.target);
					if (!el.isIntersecting && idx > -1) {
						s.splice(idx, 1);
					}
					if (el.isIntersecting && idx === -1) {
						s.push(el.target);
					}
				}
				return [...s];
			});
		},
		{ threshold: 0.7, rootMargin: '-50px 0px -25% 0px' },
	);

	createEffect(() => {
		const els = highlighted();
		const handler = mapRef();
		if (handler) {
			if (els.length && enableFocus()) {
				const slug = els[0]!.getAttribute('data-id');
				const facility = data()?.facilities.facilities.find((f) => f.slug === slug);

				if (facility && handler) {
					handler.setFocus(facility);
				}
			}
			if (!enableFocus()) {
				handler.setFocus();
			}
		}
	});

	createEffect(() => {
		if (mapExpanded()) {
			setFocused();
			setHighlighted([]);
		}
	});

	return (
		<Suspense>
			<Show when={data()?.facilities.facilities}>
				{(facilities) => (
					<>
						<Title>{props.title}</Title>
						<Meta name="description" content={props.description ?? ''} />
						<Container class="flex flex-row flex-wrap items-end justify-between gap-4 py-8">
							<div class="flex max-w-4xl flex-col items-start gap-8 text-balance text-start">{props.children}</div>

							<ShareButtons title={shareData().title} text={shareData().text} url={shareData().url} />
						</Container>

						<TeeTimeSearchHeader
							class="lg:order-first"
							classNotAtTop="border-b border-neutral"
							editIcon={IconSearchMagnifyingGlass}
							hideQueryInput
							filters={store}
							setFilters={setStore}
							trackQuery={false}
							onSubmit={() => {
								const params = new URLSearchParams(
									(toSearchSchema.safeParse({ ...store })?.data as Record<string, string>) ?? '',
								);
								params.set('query', props.location);
								const fac = Object.values(facilities())
									.map((f) => f.slug)
									.join('&facilities=');
								navigate(`/tee-times/?${params.toString()}&facilities=${fac}`);
							}}
							scrollHeader={
								<div class="flex flex-col">
									<Heading as="h2" size="h6">
										Find a Tee Time
									</Heading>
									<p class="text-wrap text-sm">Compare rates across all {props.location} courses.</p>
								</div>
							}
						/>

						<Page class="border-t border-neutral bg-neutral-100 py-0 sm:py-8">
							<Container class="px-0">
								<Grid>
									<GridFive class="order-first lg:order-2 lg:block" classList={{ 'absolute inset-0': mapExpanded() }}>
										<Section class={!mapExpanded() ? 'top-16 lg:sticky lg:top-32' : undefined}>
											<div
												class="overflow-hidden"
												classList={{
													'relative h-48 sm:rounded lg:h-[calc(100dvh-10rem)]': !mapExpanded(),
													'absolute z-50 inset-0 top-0 h-[calc(100dvh-3.5rem)]': mapExpanded(),
												}}
											>
												<Map
													ref={setMapRef}
													facilities={data.latest?.facilities.facilities ?? []}
													onExpand={(expanded) => {
														setMapExpanded(expanded);
														setEnableFocus(!expanded);
													}}
													onSelectFacility={(facility) => {
														!mapExpanded() && setEnableFocus(false);
														const index = data()?.facilities.facilities.findIndex((f) => f.slug === facility.slug);
														virtualizer()?.scrollToIndex(index ?? 0, {
															smooth: true,
															offset: SCROLL_OFFSET,
														});
														const handler = mapRef();
														if (typeof index !== 'undefined' && index > -1 && facility && handler) {
															setFocused(data()!.facilities.facilities[index]);
															!mapExpanded() && handler.setFocus(facility);
														}
														if (!mapExpanded()) {
															setTimeout(() => {
																setEnableFocus(true);
															}, 1000);
														}
													}}
												/>
											</div>
										</Section>
									</GridFive>
									<Switch>
										<Match when={!mapExpanded()}>
											<GridSeven class="relative">
												<div
													class="sticky inset-x-auto top-32 z-30 -mb-12 hidden place-content-center transition-all delay-200 duration-300 lg:grid"
													classList={{
														'-translate-y-24 opacity-0 pointer-events-none': scroll.y <= Math.abs(SCROLL_OFFSET),
														'translate-y-0 opacity-1 pointer-events-all': scroll.y > Math.abs(SCROLL_OFFSET),
													}}
												>
													<Button
														appearance="tertiary"
														class="pointer-events-auto shadow"
														onClick={() => {
															window.scrollTo({ top: 0, behavior: 'smooth' });
														}}
													>
														<IconArrowUpMd />
														Back to top
													</Button>
												</div>
												<p class="px-4 pb-8 font-medium text-neutral-700 lg:px-0">
													{data()!.facilities.totalCount} Courses
												</p>
												<WindowVirtualizer overscan={3} ref={setVirtualizer} data={facilities()} itemSize={600}>
													{(facility, index) => (
														<div
															ref={(el) => setTargets((t) => [...t, el])}
															data-id={facility.slug}
															class="px-4 pb-16 lg:px-0"
															onClick={() => {
																sessionStorage.setItem(`${sessionKeyPrefix}-${location.pathname}`, `${index}`);
															}}
														>
															<FacilityCard
																showInfoLink={false}
																facility={facility}
																bookingUrl={props.facilities[facility.slug]?.url}
																phone={props.facilities[facility.slug]?.tel}
															/>
														</div>
													)}
												</WindowVirtualizer>
												<div
													// eslint-disable-next-line tailwindcss/no-arbitrary-value
													class="hidden h-[50dvh] lg:block"
												/>
											</GridSeven>
										</Match>
										<Match when={mapExpanded() && focused()}>
											{(focused) => (
												<Dialog
													open
													key="coop-course-map"
													onOpenChange={(open) => {
														if (!open) {
															setFocused(undefined);
														}
													}}
												>
													<DialogContent height="fit" header="Course details" headerLevel="h2">
														<FacilityCard
															showInfoLink={false}
															facility={focused()}
															// @ts-expect-error fragments
															bookingUrl={props.facilities[focused().slug]?.url}
															// @ts-expect-error fragments
															phone={props.facilities[focused().slug]?.tel}
														/>
													</DialogContent>
												</Dialog>
											)}
										</Match>
									</Switch>
								</Grid>
							</Container>
						</Page>
					</>
				)}
			</Show>
		</Suspense>
	);
}

const query = gql(`query coOpFacilities($slugs: [String!]) {
	facilities: facilitiesV3(idOrSlugs: $slugs, sortBy: name) {
    facilities {
    	slug
    	...FacilityCardFragment
      ...FacilityMapInfo
    	metadata {
 	  	  hero { url }
    	}
    }
    totalCount
  }
}`);

const getFacilities = cachedQuery(query);
